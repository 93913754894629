<template>
    <div class="videos">
        <el-dialog
            :title="title"
            :visible.sync="dialogVisibleStart"
            :before-close="handleClose"
            width="70%">
            <video v-if="dialogVisibleStart" class="videos_medis" :src="videos" controls autoplay="autoplay" loop="loop"></video>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props:{
        dialogVisible:{
            type:Boolean,
            default:true,
        },
        videos:{
            type:String,
            default:'',
        },
        title:{
            type:String,
            default:'详细视频',
        },
        videoTitle: {
            type: String,
            default: '详细视频'
        }
    },
    watch:{
        'dialogVisible':{
            handler(val){
                this.dialogVisibleStart = val;
                // this.$set(this,'dialogVisibleStart',val);
                // console.log(val,this.dialogVisibleStart,111111)
            },
            deep:true
        }
    },
    data() {
        return {
            dialogVisibleStart:false,
        }
    },
    methods:{
        handleClose(){
            this.dialogVisibleStart = false
            this.$emit('onstart',false)
            // console.log(this.dialogVisibleStart)
        }
    }
}
</script>
<style scoped  lang="scss">
.videos_medis{
    width:100%;
    object-fit: contain;
    &:focus{
        outline:none;
    }
}
.videos{
    position:relative
}
::v-deep{
    .el-dialog__body{
        padding: 0!important;
    }
}
</style>