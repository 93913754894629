<template>
  <div class="BrandPresence">
    <!-- 参展企业 品牌风采 -->

    <!-- 图片 -->
    <ul class="image w clearfix" v-if="dataContentImg.length">
      <li
        class="imgVideoList"
        v-for="(item,index) in dataContentImg"
        :key="index"
        @click="overTon(item)"
      >
        <div class="diver" v-if="$route.query.type!=='img'">
          <a href="javascropt:;" >
            <div class="kuai">
              <div class="kuai-wrap warpvideo">
                <img src="../assets/images/video.png" alt />
              </div>
            </div>
            <img :src="item.cover"  class="widMaxImg" alt />
            <p class="titleP">{{item.title}}</p>
          </a>
        </div>
        <a href="javascropt:;" v-else>
          <div class="kuai">
            <div class="kuai-wrap warpvideo">
              <img src="../assets/images/video.png" alt />
            </div>
          </div>
          <el-image :src="item.image" class="imgHeight" :preview-src-list="[item.image]"></el-image>
          <p class="titleP">{{item.title}}</p>
        </a>
      </li>
    </ul>
    <empty v-else />
    <!-- 尾部 -->
    <!-- <Footer /> -->
    <!-- 分页 -->
    <el-pagination
      v-if="total!==0"
      background
      class="pageAction"
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :page-size="pageSize"
      :current-page="indexPage"
      :total="total"
    ></el-pagination>
    <videos
      :dialogVisible="startVideo"
      :videos="srcVideo"
      @onstart="onstartVideo"
      :title="titleModel"
    ></videos>
  </div>
</template>

<script>
import { bandVideoimage, videoListImg } from "@/api/index";
import videos from "@/components/videos.vue";
import empty from "@/components/emptyBox/index";
export default {
  name: "BrandPresence",
  components: {
    videos,
    empty
  },
  data() {
    return {
      titleModel: "详细视频",
      startVideo: false, // video
      srcVideo: "",
      total: 0,
      indexPage: 1,
      pageSize: 9,
      dataContentImg: []
    };
  },
  mounted() {},
  created() {
    if (this.$route.query.type === "img") {
      this.initImgList();
    } else {
      this.videoChange();
    }
  },
  methods: {
    overTon(row) {
      if (this.$route.query.type === "img") {
        return false;
      } else {
        this.srcVideo = row.video_url;
        this.startVideo = true;
      }
      this.titleModel = row.title;
    },
    onstartVideo() {
      this.startVideo = false;
    },
    videoChange(row) {
      let datas = {
        page: this.indexPage,
        limit: this.pageSize
      };
      videoListImg(datas).then(res => {
        this.dataContentImg = res.data.data;
        this.total = res.data.total;
      });
    },
    handleCurrentChange(val) {
      this.indexPage = val;
      if (this.$route.query.type) {
        this.initImgList();
      } else {
        this.videoChange();
      }
    },
    initImgList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)"
      });
      const datas = {
        limit: this.pageSize,
        page: this.indexPage
      };
      bandVideoimage(datas).then(res => {
        this.dataContentImg = res.data.data;
        this.total = res.data.total;
        loading.close();
      });
    }
  }
};
</script>

<style scoped  lang="scss">
.diver{
  height:260px!important;
}
.titleP {
  margin-top: -50px;
  font-weight: 800 !important;
  font-size: 16px !important;
  margin-top:0!important;
  color:#000!important;
  padding:20px 0;
}
.widMaxImg{
  height:260px;
}
.kuai {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
}
.warpvideo {
  position: relative;
  text-align: center;
  img {
    position: absolute;
    width: 80px !important;
    height: 80px;
    margin-left: -40px;
    margin-top: -35px;
    opacity: 0;
  }
}
.imgVideoList {
  cursor: pointer;
  position: relative;
  a {
    width: 100%;
    height: 300px;
    float: left;
    overflow: hidden;
    position: relative;
  }
  .imgHeight {
    width: 100%;
    height:260px!important;
    position: relative!important;
    overflow: hidden;
    transition: all 0.3s;
    &:hover{
      transform: scale(1.1);
    }
  }
  &:hover {
    .kuai {
      opacity: 1;
    }
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
    }
    img {
      opacity: 1;
    }
  }
}
.clearfix {
  img {
    width: 100%;
  }
}
.pageAction {
  padding: 40px;
  text-align: center;
  ::v-deep {
    .active {
      background-color: #0e9b32 !important;
    }
    li {
      &:hover {
        color: #27ea58 !important;
      }
    }
  }
}
.BrandPresence {
  padding-top: 95px;
}
.image {
  //  background: chocolate;
  margin-bottom: 90px;
  // width:1200px;

  li {
    float: left;
    margin-bottom: 18px;
    height: 310px;
    width: 385px;
    // background: red;
    margin-right: 22px;

    &:nth-child(3n) {
      margin-right: 0px;
    }

    // img
    //     margin-right: 15px;
    //   //  margin-bottom: 20px;
    //
    h5 {
      font-weight: 600;
      color: #3a3a3a;
      font-size: 16px;
      margin-top: 13px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      margin-top: 40px;
    }
  }
}

.swiper-container {
  height: 500px;
  width: 100%;

  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      // background-color: #42b983;
      text-align: center;
      line-height: 500px;
      height: 100%;
      width: 100%;
      background: url(../assets/images/banner4.jpg) no-repeat center top;
      background-size: 1920px 500px;
    }
  }
}
</style>